// 組織コードを入力するときに左を0で埋める

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("change", () => {
      let code = this.element.value
      if(code) {
        this.element.value = ('0000000000' + code).slice(-10)
      }
    })
  }
}
import Rails from "@rails/ujs"

// EP_1-7302 再コンパイル用にコメント追記
// import "@hotwired/turbo-rails" とすると webpack での 
// @hotwired/turbo-rails/app/javascript/turbo/cable.js の import() のコンパイルがうまくいかない
import "@hotwired/turbo-rails/app/assets/javascripts/turbo"

import jquery from "jquery"
window.$ = window.jQuery = jquery
window.bootstrap = require("bootstrap5")

Rails.start()

import { application } from "./controllers/application"
import ZeroPaddingController from "./controllers/common/zero_padding_controller"

application.register("zero-padding", ZeroPaddingController)
